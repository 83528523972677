body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
select:focus,
textarea:focus,
input:focus {
  outline: none;
}
select,
input,
textarea {
  color-scheme: light;
  background-color: white;
}

.mirror-Animation {
  position: relative;
  overflow: hidden;
}
.mirror-Animation::before {
  content: "";
  position: absolute;
  rotate: 50deg;
  height: 500px;
  bottom: -200px;
  background: linear-gradient(90deg, #f0f5fe00 0%, #f0f5fecc 100%);
  opacity: 0.4;
  animation: mirror-animation 3.5s linear infinite;
}

.custom-blue-pulse {
  animation: 1.5s custom-pulse-blue-animation infinite ease-in-out;
}
.custom-red-pulse {
  animation: 1.5s custom-pulse-red-animation infinite ease-in-out;
}

@keyframes custom-pulse-blue-animation {
  0% {
    box-shadow: 0 0 0 0 #2136d4;
  }

  100% {
    box-shadow: 0 0 0 15px #2136d400;
  }
}
@keyframes custom-pulse-red-animation {
  0% {
    box-shadow: 0 0 0 0 #ff0000;
  }

  100% {
    box-shadow: 0 0 0 15px #ff000000;
  }
}

.text-gradient-blue {
  background: -webkit-linear-gradient(#2136d4, #878dfe);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes mirror-animation {
  0% {
    left: -100%;
  }
  30% {
    left: -100%;
  }
  100% {
    left: 150%;
  }
}

/* loader/spinner css start */
.loader {
  animation: rotation 1s linear infinite;
}
.spinner {
  animation: rotation 2s linear infinite;
}
.popAnime {
  animation: popAnime 1s linear infinite;
}

@keyframes popAnime {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .loader {
    @apply aspect-square rounded-[50%] inline-block border-t-[3px] border-r-[3px] box-border border-[white];
  }

  .blue-gradient {
    @apply bg-gradient-to-br  bg-[#2136d4] from-blue-500 via-blue-600 to-blue-900 text-white;
  }

  .green-gradient {
    @apply bg-gradient-to-br bg-green-500 from-green-500 via-green-600 to-green-900 text-white;
  }
  .red-gradient {
    @apply bg-gradient-to-br bg-red-500 from-red-500 via-red-600 to-red-900 text-white;
  }
  .gray-gradient {
    @apply bg-gradient-to-br bg-gray-600 from-gray-500 via-gray-600 to-gray-900 text-white;
  }

  .box-container {
    @apply border w-full border-gray-200 bg-white rounded-lg flex flex-col  items-center p-4;
  }

  .dashLine {
    @apply border-t border-gray-300 w-full my-2 h-0 border-dashed;
  }

  .custom-blue-pulse {
    @apply h-4 w-4 bg-[#2136d4] rounded-full;
  }
  .custom-red-pulse {
    @apply h-4 w-4 bg-[#ff0000] rounded-full;
  }

  .blue-gradient-to-top {
    @apply bg-gradient-to-b  from-blue-400  to-white;
  }

  .input2 {
    @apply border border-[#e7e7e7] rounded-lg text-[.875rem] p-1;
  }

  .img {
    @apply border border-[#e7e7e7] rounded-lg bg-[#f0f5fe] w-[4rem] h-[4rem];
  }
}
