@import url("https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600;700&display=swap");

* {
  margin: 0;
  padding: 0;
}

/* some pre defined css */

.scroll-bar-remove::-webkit-scrollbar {
  display: none;
}

.ebo_skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

/* loader/spinner css start */
.loader {
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #2136d4;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* loader/spinner css  end */

/* mirror animation */
/* define the widht where you want to use */
.mirror-Animation {
  position: relative;
  overflow: hidden;
}
.mirror-Animation::before {
  content: "";
  position: absolute;
  rotate: 50deg;
  height: 500px;
  bottom: -200px;
  background: linear-gradient(90deg, #f0f5fe00 0%, #f0f5fecc 100%);
  opacity: 0.4;
  animation: mirror-animation 3.5s linear infinite;
}

@keyframes mirror-animation {
  0% {
    left: -100%;
  }
  30% {
    left: -100%;
  }
  100% {
    left: 150%;
  }
}

input {
  line-height: normal;
}
input:focus {
  outline: none;
}

a:hover {
  text-decoration: none;
  color: #565050;
}
a {
  color: #565050;
}
/* nav design just for test purpose  */
